import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchCurrentPlan } from "../../../../shared/hooks/useFetchCurrentPlan";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";
import DwLogutil from "../../Utils/DwLogutil";
import { EC_DW_LOGGING_STEPS } from "../../../../shared/utils/Constants";

type nextPaymentDetailsInitialStateType = {
  nextPaymentDetails: any;
  loading: boolean;
  error: any;
};
const initialState: nextPaymentDetailsInitialStateType = {
  nextPaymentDetails: null,
  loading: false,
  error: null,
};

export const getNextPaymentDetails = createAsyncThunk(
  "nextPayment/details",
  async (billingAccountNumber: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const params = {
        [API_URLS?.upcomingPayment_QA_Url]: billingAccountNumber,
      };
      const response = await fetchCurrentPlan(params, headers);
      if(response?.data?.dueDate && response?.data?.nextPayment) {
        DwLogutil({stepName: EC_DW_LOGGING_STEPS.upcoming_payment_success})
      } else {
        DwLogutil({stepName: EC_DW_LOGGING_STEPS.upcoming_payment_failed, error: "Response data is empty"});
      }
      return response.data;
    } catch (error) {
      DwLogutil({stepName: EC_DW_LOGGING_STEPS.upcoming_payment_failed, error: "somthing went wrong while fetching the API details"});
      throw error;
    }
  }
);

const nextPaymentDetailSlice = createSlice({
  name: "nextPayment/Details",
  initialState,
  reducers: {
    clearNextPaymentDetailSlice: (state) => {
      return initialState;
    },
    nextPaymentDetailSliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getNextPaymentDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNextPaymentDetails.fulfilled, (state, action) => {
      state.nextPaymentDetails = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getNextPaymentDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "next payment",
        action.error?.message,
        action?.type
      );
      state.nextPaymentDetails = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const {
  clearNextPaymentDetailSlice,
  nextPaymentDetailSliceSetInitialState,
} = nextPaymentDetailSlice.actions;
export default nextPaymentDetailSlice.reducer;
