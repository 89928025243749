import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAccountInfoWithBanNZIPCode,
  addAccountPostCall,
} from "../../../../shared/hooks/useFetchAccountInfo";
import store from "../store";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { v4 as uuidv4 } from "uuid";
type AccountDetailsByBANNZIPInitialStateType = {
  AccountDetailsByBANNZIP: any;
  loading: boolean;
  error: any;
  addAccountCustomerId: string;
  newAccountPrincipalName: string;
  checkAccountBanZipLoading: boolean;
  forgetAccountError: any;
  forgetAccountLoading: boolean;
  forgetAccountResponseData: any;
  sendOtpError: any;
  sendOtpLoading: boolean;
  sendOtpResponseData: any;
};

const initialState: AccountDetailsByBANNZIPInitialStateType = {
  AccountDetailsByBANNZIP: null,
  loading: false,
  error: null,
  addAccountCustomerId: "",
  newAccountPrincipalName: "",
  checkAccountBanZipLoading: false,
  forgetAccountError: null,
  forgetAccountLoading: false,
  forgetAccountResponseData: null,
  sendOtpError: null,
  sendOtpLoading: false,
  sendOtpResponseData: null,
};

export const getAccountDetailsByBANNZIP = createAsyncThunk(
  "addNewAccount/slice/checkBAN",
  async (payloadData: any) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;

      const response = await fetchAccountInfoWithBanNZIPCode(
        {
          "": `${API_URLS?.getAccountDetailsByBANNZIP}`,
        },
        { ban: payloadData?.ban, zipCode: payloadData?.zipCode },
        headers
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const forgotAccountNumber = createAsyncThunk(
  "addNewAccount/slice/forgotaccountid",
  async (userDetails: any) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const wirecenter = reduxStore?.addressQualification?.data?.addressQualification?.entity?.wireCenter;

      const response = await addAccountPostCall(
        {
          "": `${API_URLS?.forgot_account_number}`,
        },

        {
          baseline: {
            transactionId: uuidv4(),
            channel: "accountManagement",
            wirecenter: wirecenter || "",
            siteId: headers?.siteId,
            partnerOrderId: "",
            intent: "",
          },
          accountDetailRequest: {
            email: userDetails?.email,
            contactNumber: "",
            communicationPreference: "email",
            userPrincipalName:userDetails?.userPrincipalName,
            customerId: userDetails?.customerId
          },
        },
        headers
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addAccSendOtp = createAsyncThunk(
  "addNewAccount/slice/sendaccountotp",
  async (userRequest: any) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const wirecenter = reduxStore?.addressQualification?.data?.addressQualification?.entity?.wireCenter;

      const response = await addAccountPostCall(
        {
          "": `${API_URLS?.add_account_send_Otp}`,
        },

        {
          baseline: {
            transactionId: uuidv4(),
            channel: "accountManagement",
            wirecenter: wirecenter || "",
            siteId: headers?.siteId,
            partnerOrderId: "",
            intent: "",
          },
          accountDetailRequest: {
            email: userRequest?.email,
            contactNumber: userRequest?.contactNumber,
            communicationPreference: userRequest?.communicationPreference,
            userPrincipalName: userRequest?.userPrincipalName,
            customerId: userRequest?.customerId
          },
        },
        headers
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const AccountDetailsByBANNZIPSlice = createSlice({
  name: "addNewAccount/slice",
  initialState,
  reducers: {
    addAccountCustomerId: (state, action) => {
      state.addAccountCustomerId = action.payload;
    },
    addAccountPrincipalName: (state, action) => {
      state.newAccountPrincipalName = action.payload;
    },
    banZipLoading: (state, action) => {
      state.checkAccountBanZipLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountDetailsByBANNZIP.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAccountDetailsByBANNZIP.fulfilled, (state, action) => {
      state.AccountDetailsByBANNZIP = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAccountDetailsByBANNZIP.rejected, (state, action) => {
      state.AccountDetailsByBANNZIP = null;
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(forgotAccountNumber.pending, (state) => {
      state.forgetAccountLoading = true;
    });
    builder.addCase(forgotAccountNumber.fulfilled, (state, action) => {
      state.forgetAccountResponseData = action.payload;
      state.forgetAccountLoading = false;
      state.forgetAccountError = null;
    });
    builder.addCase(forgotAccountNumber.rejected, (state, action) => {
      state.forgetAccountResponseData = null;
      state.forgetAccountLoading = false;
      state.forgetAccountError = action.error;
    });

    builder.addCase(addAccSendOtp.pending, (state) => {
      state.sendOtpLoading = true;
    });
    builder.addCase(addAccSendOtp.fulfilled, (state, action) => {
      state.sendOtpResponseData = action.payload;
      state.sendOtpLoading = false;
      state.sendOtpError = null;
    });
    builder.addCase(addAccSendOtp.rejected, (state, action) => {
      state.sendOtpResponseData = null;
      state.sendOtpLoading = false;
      state.sendOtpError = action.error;
    });
  },
});
export const { addAccountCustomerId, addAccountPrincipalName, banZipLoading } =
  AccountDetailsByBANNZIPSlice.actions;
export default AccountDetailsByBANNZIPSlice.reducer;
