import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { addressQualification } from "../../../../shared/hooks/useFetchCompleteAddress";
import API_URLS from "../../../../shared/utils/ApiUrls";
import store from "../store";

type addressQualifucationInitialStateType = {
  data: any;
  loading: boolean;
  error: any;
  networkType: any;
  customAddressQualificationLoader: boolean,
};
const initialState: addressQualifucationInitialStateType = {
  data: null,
  loading: false,
  error: null,
  networkType: null,
  customAddressQualificationLoader: false,
};

export const getAddressQualification = createAsyncThunk(
  "get/address/qualification",
  async (user: any) => {
    try {
      const serviceAdd = user?.addresses?.find(
        (addr: any) => addr?.addressType === "ServiceAddress"
      );
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      if (
        serviceAdd &&
        serviceAdd?.street &&
        serviceAdd?.city &&
        serviceAdd?.state &&
        serviceAdd?.addressId
      ) {
        const addressQualificationRes = await addressQualification(
          {
            "": `${API_URLS?.addressQualificationURL}?addressId=${serviceAdd?.addressId}&serviceInstanceId=${user?.serviceInstanceId || ""}&existingUser=EC`,
          },
          headers
        );
        if (
          addressQualificationRes?.status === 200 &&
          addressQualificationRes?.data && 
          addressQualificationRes?.data?.status !== 500 &&
          addressQualificationRes?.data?.entity
        ) {
          return {
            addressQualification: addressQualificationRes?.data,
          };
        } else {
          throw new Error("Something went wrong with address qualification");
        }
      } else {
        throw new Error("Invalid user service address");
      }
    } catch (error) {
      throw error;
    }
  }
);

const addressQualificationSlice = createSlice({
  name: "address/qualification",
  initialState,
  reducers: {
    clearAddressQualificationState: (state) => {
      state.data = null;
      state.error = null;
      state.loading = false;
    },
    addressQualificationSliceSetInitialState: () => initialState,
    updateAddressQualificationSlice: (state, action) => {
      state.data = action.payload;
      if (action.payload?.addressQualification?.entity?.networkType) {
        state.networkType =
          action.payload?.addressQualification?.entity?.networkType;
      }
    },
    setAddressQualificationLoader: (state, action) => {
      state.customAddressQualificationLoader = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAddressQualification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAddressQualification.fulfilled, (state, action) => {
      state.data = action.payload;
      state.networkType =
        action.payload?.addressQualification?.entity?.networkType;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAddressQualification.rejected, (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const {
  clearAddressQualificationState,
  addressQualificationSliceSetInitialState,
  updateAddressQualificationSlice,
  setAddressQualificationLoader
} = addressQualificationSlice.actions;
export default addressQualificationSlice.reducer;
