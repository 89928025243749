import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import fetchAccountInfo, {
  fetchAccountInfoForAgent,
  fetchAccountInfoCTL,
  fetchUserAccountInfo,
} from "../../../../shared/hooks/useFetchAccountInfo";
import { dwLogging, generateSessionId } from "../../../../shared/hooks/useFetchLogin";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import useBrightspeedHeaders from "../../hooks/useBrightspeedHeader";
import store from "../store";
import { addressQualification } from "../../../../shared/hooks/useFetchCompleteAddress";
import { updateAddressQualificationSlice } from "./addressQualification";
import { setHeaders } from "./brightspeedToken";
import { add } from "date-fns";
import { EC_DW_LOGGING_STEPS } from "../../../../shared/utils/Constants";
import DwLogutil from "../../Utils/DwLogutil";

type userDetailsForAgentFlow = {
  ban: string;
  addressId?: string
}

export enum flowType {
  "login",
  "forget_username",
  "silent_login",
  "silent_registration",
  "forget_password",
  "registration", // user availabe in c60 but not exist in azure
  "account_lock",
  "login_with_recaptcha",
  "set_password_first_time", // it's only for new placed order is uder coming from email link for setting a password
  "set_password_first_time_with_catpcha"
}

type userInitialStateType = {
  acp: string;
  acpModalStatus: boolean;
  data: any;
  loading: boolean;
  error: any;
  token: string;
  jwtToken: string;
  refreshToken: string;
  rootToken: string;
  clientId: string;
  changePassClientId: string;
  principalName: string;
  accountEnabled: string;
  companyName: string;
  otp: string;
  forgotPassPrefMethod: string;
  canUserResetPass: boolean;
  emailOnlyForRegister: string;
  hashEmailOnlyForRegister: string;
  phoneNoHash: string;
  accountIdOnlyForRegister: string;
  sessionId: string;
  contactNumber: string;
  isCaptchaChecked: boolean;
  isCaptchaLoading: boolean;
  forgotEmailId: string;
  appDownloadModalHideAsDoNotShowAgain: boolean;
  reOpenAppDownloadModal: boolean;
  emailBeforeLogin: string;
  userNameBeforeLogin: string;
  isCtlUserBeforeLogin: boolean;
  isUserSettingPassFromEmailLink: boolean;
  isSilentLogin: boolean;
  originalPrimaryEmail: string;
  forgotPassSelectedValue: any;
  autoPayEnrolled: boolean;
  manageAccount: any;
  userCustomerId: string;
  logoutClickedValue: boolean;
  userData: any;
  userSwitchingStatus: boolean;
  userLocked: string;
  listOfUserLoder: boolean;
  listOfUserError: any;
  loginUserBan: string;
  loginUserAddressId: string;
  forgetPassPayloadForResendOTP: any;
  registerUserInAZ: any;
  logedinUserName: string;
  forgetUserNameContactNumber: string;
  flowType: flowType;
};

const initialState: userInitialStateType = {
  acp: "no",
  acpModalStatus: false,
  data: null,
  loading: false,
  error: null,
  token: localStorage?.getItem("token") || "",
  jwtToken: "",
  refreshToken: localStorage?.getItem("iamrt") || "",
  rootToken: localStorage?.getItem("rootToken") || "",
  clientId: "",
  changePassClientId: "",
  principalName: "",
  accountEnabled: "",
  companyName: "",
  otp: "",
  forgotPassPrefMethod: "",
  canUserResetPass: false,
  emailOnlyForRegister: "",
  hashEmailOnlyForRegister: "",
  phoneNoHash: "",
  accountIdOnlyForRegister: "",
  sessionId: localStorage?.getItem("sessionId") || "",
  contactNumber: "",
  isCaptchaChecked: false,
  isCaptchaLoading: false,
  isCtlUserBeforeLogin: false,
  forgotEmailId: "",
  appDownloadModalHideAsDoNotShowAgain: localStorage.getItem(
    "appDownloadModalHideAsDoNotShowAgain"
  )
    ? true
    : false,
  reOpenAppDownloadModal: false,
  emailBeforeLogin: "",
  userNameBeforeLogin: "",
  isUserSettingPassFromEmailLink: false,
  isSilentLogin: false,
  originalPrimaryEmail: localStorage.getItem("originalPrimaryEmail") || "",
  userCustomerId: localStorage.getItem("custId") || "",
  userData: null,
  forgotPassSelectedValue: "",
  manageAccount: "",
  userSwitchingStatus: false,
  userLocked: localStorage.getItem("lockedUser") || "",
  listOfUserLoder: false,
  listOfUserError: null,
  autoPayEnrolled: false,
  logoutClickedValue: false,
  loginUserBan: localStorage.getItem("loginUserBan") || "",
  loginUserAddressId: localStorage.getItem("loginUserAddressId") || "",
  forgetPassPayloadForResendOTP: null,
  registerUserInAZ: null,
  logedinUserName:"",
  forgetUserNameContactNumber: "",
  flowType: localStorage.getItem("flowType") ? parseInt(localStorage.getItem("flowType") || "0") : flowType.login
};

export const getUserDetails = createAsyncThunk(
  "user/Details",
  async (email: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const ban = reduxStore?.user?.loginUserBan;
      const addId = reduxStore?.user?.loginUserAddressId;
      const response = await fetchUserAccountInfo(
        // const response = await fetchAccountInfo(
        // {
        //   [API_URLS?.accountManagementUrl]: `account-details-user-secure`,
        // },
        // {
        //   username: email.toLowerCase(),
        //   token: reduxStore?.user?.refreshToken,
        // },
        {
          [API_URLS?.accountManagementUrl]: `get-account-details?ban=${ban}&addressId=${addId}`, //?ban=300851067&addressId=237798650
        },
        headers
      );

      // for jeopardy status will be is Past payment due
      // response.data.currentStatus = "Past payment due";
      // response.data.currentStatus = "Suspended";
      // response.data.suspendReason = "Payment Failed";

      if (
        response?.data?.suspendReason === "Payment Failed" &&
        !["Active", "Inactive"]?.includes(response?.data?.currentStatus)
      ) {
        response.data.isSuspended = true;
      }

      if (response?.data?.email) {
        response.data.suspendStartDate =
          response?.data?.suspendStartDate?.trim() || "";
        response.data.suspendEndDate =
          response?.data?.suspendEndDate?.trim() || "";
      }
      if(response?.data?.billingType){
        response.data.billingType = response.data.billingType.toLowerCase()
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUserDetailsForAgent = createAsyncThunk(
  "user/Details-agent",
  async (userDetails: userDetailsForAgentFlow) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      let API_URL_FOR_AGENT = `${API_URLS?.userInfoByAccIdUrl}?accountID=${userDetails?.ban}`
      if(userDetails?.addressId) {
        API_URL_FOR_AGENT += `&addressId=${userDetails?.addressId}`
      }
      const response = await fetchAccountInfoForAgent(
        {
          "": API_URL_FOR_AGENT,
        },
        headers
      );
      if (
        response.data?.collections?.customer?.length &&
        response.data?.collections?.customer[0]?.billingAccountNumber
      ) {
        return response.data?.collections?.customer[0];
      } else {
        throw new Error("Invalid user details");
      }
    } catch (error) {
      throw error;
    }
  }
);
export const getUserDetailsInfo = createAsyncThunk(
  "user/Details-info",
  async (customerId: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const response = await fetchUserAccountInfo(
        {
          [API_URLS?.accountManagementUrl]: `getUserAccountDetails?customerId=${customerId}`,
        },
        headers
      );

      // for jeopardy status will be is Past payment due
      // response.data.currentStatus = "Past payment due";
      // response.data.currentStatus = "Suspended";
      // response.data.suspendReason = "Payment Failed";

      if (
        response?.data?.suspendReason === "Payment Failed" &&
        !["Active", "Inactive"]?.includes(response?.data?.currentStatus)
      ) {
        response.data.isSuspended = true;
      }
      if(response?.data?.billingType){
        response.data.billingType = response.data.billingType.toLowerCase()
      }
      if(response?.data?.length === 0) {
        DwLogutil({
          stepName: EC_DW_LOGGING_STEPS.userNotFountWith_cust_ID_Not_Found,
          error: "User not found in c360 with CustID"
        })
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getAccountDetailsInfo = createAsyncThunk(
  "user/account-info",
  async (dataParam: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const cName = reduxStore?.user?.companyName;
      const response = await fetchUserAccountInfo(
        {
          [API_URLS?.accountManagementUrl]: `get-account-details?${dataParam}`, //?ban=300851067&addressId=237798650
        },
        headers
      );
      // below API call is for siteId when user switch the account then after user detail in All other APi it should pass as header
      const serviceAdd =
        response?.data?.addresses?.find(
          (addr: any) => addr?.addressType === "ServiceAddress"
        ) || "";
      if (
        serviceAdd &&
        serviceAdd?.street &&
        serviceAdd?.city &&
        serviceAdd?.state &&
        serviceAdd?.addressId
      ) {
        const addressQualificationRes = await addressQualification(
          {
            "": `${API_URLS?.addressQualificationURL}?addressId=${serviceAdd?.addressId}&serviceInstanceId=${response?.data?.serviceInstanceId || ""}&existingUser=EC`,
          },
          headers
        );
        if (
          addressQualificationRes?.status === 200 &&
          addressQualificationRes?.data
        ) {
          if (
            ["BF_QUALIFIED", "QUALIFIED"]?.includes(
              addressQualificationRes?.data?.entity?.brightSpeedFiberIndicator?.toUpperCase()
            )
          ) {
            if(!cName) {
              store?.dispatch(addCompanyName("bs"));
            }
            store?.dispatch(
              setHeaders({
                ...headers,
                siteId: "bs",
              })
            );
            localStorage.setItem("headersSiteId", "bs");
          } else if (
            ["QF_QUALIFIED", "QF QUALIFIED"]?.includes(
              addressQualificationRes?.data?.entity?.brightSpeedFiberIndicator?.toUpperCase()
            )
          ) {
            if(!cName) {
              store?.dispatch(addCompanyName(addressQualificationRes?.data?.entity?.instantInternet
                ? "qf-ii"
                : "qf"));
            }
            store?.dispatch(
              setHeaders({
                ...headers,
                siteId: addressQualificationRes?.data?.entity?.instantInternet
                  ? "qf-ii"
                  : "qf",
              })
            );
            localStorage.setItem(
              "headersSiteId",
              addressQualificationRes?.data?.entity?.instantInternet
                ? "qf-ii"
                : "qf"
            );
          } else if (
            ["CTL QUALIFIED", "CTL_QUALIFIED", "COPPER QUALIFIED"]?.includes(
              addressQualificationRes?.data?.entity?.brightSpeedFiberIndicator?.toUpperCase()
            )
          ) {
            if(!cName) {
              store?.dispatch(addCompanyName("ctl"));
            }
            store?.dispatch(
              setHeaders({
                ...headers,
                siteId: "ctl",
              })
            );
            localStorage.setItem("headersSiteId", "ctl");
            dwLogging(
              {
                "": API_URLS?.dwLoggingUrl,
              },
              {
                sessionId: headers?.sessionId || localStorage?.getItem("sessionId") || "",
                stepName: EC_DW_LOGGING_STEPS.CTL_User_With_State?.replace('<VALUE>', serviceAdd?.state),
                cta: "Success",
                userEmail: response?.data?.email || "",
                customerType: headers?.companyName || "ctl",
              },
              headers
            );
          }

          if (
            addressQualificationRes?.data?.entity?.brightSpeedFiberIndicator
          ) {
            store?.dispatch(
              updateAddressQualificationSlice({
                addressQualification: addressQualificationRes?.data,
              })
            );
          }
        }
      }

      // for jeopardy status will be is Past payment due
      // response.data.currentStatus = "Past payment due";
      // response.data.currentStatus = "Suspended";
      // response.data.suspendReason = "Payment Failed";

      if (
        response?.data?.suspendReason === "Payment Failed" &&
        !["Active", "Inactive"]?.includes(response?.data?.currentStatus)
      ) {
        response.data.isSuspended = true;
      }

      if (response?.data?.email) {
        response.data.suspendStartDate =
          response?.data?.suspendStartDate?.trim() || "";
        response.data.suspendEndDate =
          response?.data?.suspendEndDate?.trim() || "";
      }
      if(response?.data?.billingType){
        response.data.billingType = response.data.billingType.toLowerCase()
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getSessionId = createAsyncThunk("user/sessionId", async () => {
  try {
    const reduxStore = store.getState();
    const headers = reduxStore?.brightspeedToken?.headers;
    const response = await generateSessionId({}, headers);
    localStorage?.setItem("sessionId", response.data?.sessionId);
    return response.data.sessionId;
  } catch (error) {
    throw error;
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAcpStatus: (state, action) => {
      state.acp = action.payload.toLowerCase();
      return state;
    },
    setAcpModalStatus: (state, action) => {
      state.acpModalStatus = action.payload;
      return state;
    },
    addToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    addRootToken: (state, action) => {
      state.rootToken = action.payload;
      return state;
    },
    logoutClicked: (state, action) => {
      if (action.payload) {
        localStorage.removeItem("redirectPath");
      }
      state.logoutClickedValue = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("switchedUserBAN");
      localStorage.removeItem("switchedUserAddId");
      localStorage.removeItem("iamrt");
      localStorage.removeItem("originalPrimaryEmail");
      localStorage.removeItem("alreadyShownPaymentMethodMsg");
      localStorage.removeItem("loginUserAddressId");
      localStorage.removeItem("loginUserBan");
      state.data = null;
      state.error = null;
      state.loading = false;
      state.token = "";
      state.originalPrimaryEmail = "";
      state.refreshToken = "";
      localStorage.removeItem("changePending");
      localStorage.removeItem("headersSiteId");
      localStorage.removeItem("custId");
      state.manageAccount = "";
    },
    addClientId: (state, action: PayloadAction<string>) => {
      state.clientId = action.payload;
    },
    removeClientId: (state) => {
      state.clientId = "";
    },
    addOTP: (state, action: PayloadAction<string>) => {
      state.otp = action.payload;
    },
    setCanUserResetPass: (state, action: PayloadAction<boolean>) => {
      state.canUserResetPass = action.payload;
    },
    setPrefMethod: (state, action: PayloadAction<string>) => {
      state.forgotPassPrefMethod = action.payload;
    },
    setContactNumber: (state, action: PayloadAction<string>) => {
      state.contactNumber = action.payload;
    },
    addChangePassClientId: (state, action: PayloadAction<string>) => {
      state.changePassClientId = action.payload;
    },
    removeChangePassClientId: (state) => {
      state.changePassClientId = "";
    },
    addPrincipalName: (state, action: PayloadAction<string>) => {
      state.principalName = action.payload;
    },
    addFirstTimeSetPasswordAccountEnabled: (
      state,
      action: PayloadAction<string>
    ) => {
      state.accountEnabled = action.payload;
    },
    addCompanyName: (state, action: PayloadAction<string>) => {
      localStorage?.setItem("companyName", action?.payload);
      state.companyName = action.payload;
    },
    removeCompanyName: (state) => {
      localStorage?.removeItem("companyName");
      state.companyName = "";
    },
    removePrincipalName: (state) => {
      state.principalName = "";
    },
    addJwtTokens: (state, action: PayloadAction<string>) => {
      state.jwtToken = action.payload;
    },
    addRefreshTokens: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    addEmailForRegisterOnly: (state, action: PayloadAction<string>) => {
      state.emailOnlyForRegister = action.payload;
    },
    addEmailHash_ForRegisterOnly: (state, action: PayloadAction<string>) => {
      state.hashEmailOnlyForRegister = action.payload;
    },
    addPhoneNoHash: (state, action: PayloadAction<string>) => {
      state.phoneNoHash = action.payload;
    },
    addAccountIdForRegisterOnly: (state, action: PayloadAction<string>) => {
      state.accountIdOnlyForRegister = action.payload;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setIsCaptchaChecked: (state) => {
      state.isCaptchaChecked = true;
    },
    startCaptchaLoading: (state) => {
      state.isCaptchaLoading = true;
    },
    stopCaptchaLoading: (state) => {
      state.isCaptchaLoading = false;
    },
    addForgotEmailId: (state, action: PayloadAction<string>) => {
      state.forgotEmailId = action.payload;
    },
    setAppDownloadModalHideAsDoNotShowAgain: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.appDownloadModalHideAsDoNotShowAgain = action.payload;
    },
    setReOpenAppDownloadModal: (state, action: PayloadAction<boolean>) => {
      state.reOpenAppDownloadModal = action.payload;
    },
    addEmailBeforeLogin: (state, action: PayloadAction<string>) => {
      state.emailBeforeLogin = action.payload;
    },
    addUserNameBeforeLogin: (state, action: PayloadAction<string>) => {
      state.userNameBeforeLogin = action.payload;
    },
    isCtlUserBeforeLogin: (state, action: PayloadAction<boolean>) => {
      state.isCtlUserBeforeLogin = action.payload;
    },
    setIsUserSettingPassFromEmailLink: (state) => {
      state.isUserSettingPassFromEmailLink = true;
    },
    setIsSilentLogin: (state, action) => {
      state.isSilentLogin = action.payload;
    },
    setOriginalPrimaryEmail: (state, action) => {
      if (action.payload) {
        localStorage.setItem("originalPrimaryEmail", action.payload);
      } else {
        localStorage.removeItem("originalPrimaryEmail");
      }
      state.originalPrimaryEmail = action.payload;
    },
    addUserCustomerId: (state, action: PayloadAction<string>) => {
      state.userCustomerId = action.payload;
    },
    addUserDetails: (state, action: PayloadAction<any>) => {
      state.userData = action.payload;
    },
    addEmailPhoneForOtp: (state, action: PayloadAction<any>) => {
      state.forgotPassSelectedValue = action.payload;
    },
    setAutoPayEnrolled: (state, action) => {
      state.autoPayEnrolled = action.payload;
    },
    switchUserStart: (state) => {
      state.userSwitchingStatus = true;
    },
    switchUserStop: (state) => {
      state.userSwitchingStatus = false;
    },
    setUserLockStatus: (state, action: PayloadAction<any>) => {
      localStorage.setItem("lockedUser", action?.payload);
      state.userLocked = action?.payload;
    },
    setAddUserForRegisterAz: (state, action: PayloadAction<any>) => {
      state.registerUserInAZ = action?.payload;
    },
    updateLastLoginUserBan: (state, action: PayloadAction<string>) => {
      localStorage.setItem("loginUserBan", action?.payload);
      state.loginUserBan = action?.payload;
    },
    updateLastLoginUserAddressId: (state, action: PayloadAction<string>) => {
      localStorage.setItem("loginUserAddressId", action?.payload);
      state.loginUserAddressId = action?.payload;
    },
    updateForgetPassPayloadForResendOTP: (
      state,
      action: PayloadAction<any>
    ) => {
      state.forgetPassPayloadForResendOTP = action?.payload;
    },
    addUserName: (state, action: PayloadAction<string>) => {
      state.logedinUserName = action.payload;
    },
    addForgetUserNameContactNumber: (state, action: PayloadAction<string>) => {
      state.forgetUserNameContactNumber = action.payload;
    },
    updateFlowType: (state, action: PayloadAction<flowType>) => {
      const flowTypeIndex: any = action.payload;
      localStorage.setItem("flowType", flowTypeIndex);
      state.flowType = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.data = action.payload;
      state.acp = action?.payload?.acp?.toLowerCase();
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "user details",
        action.error?.message,
        action?.type
      );
      state.data = null;
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(getUserDetailsForAgent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDetailsForAgent.fulfilled, (state, action) => {
      state.data = action.payload;
      state.acp = action?.payload?.acp?.toLowerCase();
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getUserDetailsForAgent.rejected, (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(getSessionId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSessionId.fulfilled, (state, action) => {
      state.sessionId = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getSessionId.rejected, (state, action) => {
      state.sessionId = "";
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(getUserDetailsInfo.pending, (state) => {
      state.listOfUserLoder = true;
    });
    builder.addCase(getUserDetailsInfo.fulfilled, (state, action) => {
      state.manageAccount = action.payload;
      //state.data = action.payload;
      state.acp = action?.payload?.acp?.toLowerCase();
      state.listOfUserLoder = false;
      state.listOfUserError = null;
    });
    builder.addCase(getUserDetailsInfo.rejected, (state, action) => {
      state.manageAccount = "";
      state.listOfUserLoder = false;
      state.listOfUserError = action.error;
    });

    builder.addCase(getAccountDetailsInfo.pending, (state) => {
      state.loading = true;
      state.data = null;
    });
    builder.addCase(getAccountDetailsInfo.fulfilled, (state, action) => {
      state.data = action.payload;
      state.acp = action?.payload?.acp?.toLowerCase();
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAccountDetailsInfo.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "user details",
        action.error?.message,
        action?.type
      );
      state.data = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const {
  setAcpStatus,
  setAcpModalStatus,
  addToken,
  logout,
  addClientId,
  removeClientId,
  addJwtTokens,
  addRefreshTokens,
  startLoading,
  stopLoading,
  addFirstTimeSetPasswordAccountEnabled,
  addPrincipalName,
  removePrincipalName,
  addCompanyName,
  removeCompanyName,
  addChangePassClientId,
  removeChangePassClientId,
  addOTP,
  setPrefMethod,
  setCanUserResetPass,
  addAccountIdForRegisterOnly,
  addEmailForRegisterOnly,
  setContactNumber,
  setIsCaptchaChecked,
  addRootToken,
  startCaptchaLoading,
  stopCaptchaLoading,
  addForgotEmailId,
  setAppDownloadModalHideAsDoNotShowAgain,
  setReOpenAppDownloadModal,
  addEmailBeforeLogin,
  addUserNameBeforeLogin,
  isCtlUserBeforeLogin,
  setIsUserSettingPassFromEmailLink,
  setIsSilentLogin,
  setOriginalPrimaryEmail,
  addEmailPhoneForOtp,
  setAutoPayEnrolled,
  addUserCustomerId,
  logoutClicked,
  addUserDetails,
  switchUserStart,
  switchUserStop,
  setUserLockStatus,
  addEmailHash_ForRegisterOnly,
  addPhoneNoHash,
  updateLastLoginUserBan,
  updateLastLoginUserAddressId,
  updateForgetPassPayloadForResendOTP,
  setAddUserForRegisterAz,
  addUserName,
  addForgetUserNameContactNumber,
  updateFlowType
} = userSlice.actions;
export default userSlice.reducer;
