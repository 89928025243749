import ApiUrls from "../../../shared/utils/ApiUrls";
import { dwLogging } from "../../../shared/hooks/useFetchLogin";
import store from "../SharedContextStore/store";
import { setHeaders } from "../SharedContextStore/slices/brightspeedToken";

interface DWLogMain {
  sessionId?: string;
  stepName: string; 
}

interface DwLogutilProps extends DWLogMain {
  headers?: Object;
  email?: string;
  companyName?: any;
  error?: string;
  flowType?: string;
  // response?: string;
};

interface DwLogBody extends DWLogMain {
  [x: string]: string | undefined;
  cta: string;
  userEmail: string
  customerType: any
};

const DwLogutil = ({
  headers,
  sessionId,
  email,
  companyName, 
  stepName,
  error,
  flowType
}: DwLogutilProps) => {
  if ( !(stepName) ) {
    console.info('DW Log missing Step Name');
    return
  };

  const endpoint = { "" : ApiUrls?.dwLoggingUrl };

  const reduxStore = store.getState();
  const headerFromStore = reduxStore?.brightspeedToken?.headers;
  const sessionIdFromStore = reduxStore?.user?.sessionId;
  const emailIdFromStore = reduxStore?.user?.data?.email;
  const companyNameFromStore = reduxStore?.user?.companyName;
  
  const body: DwLogBody = {
    stepName,
    cta : error ? 'Failed' : 'Success',
    userEmail: email || emailIdFromStore,
    sessionId : sessionId || sessionIdFromStore,
    customerType: companyName || companyNameFromStore || localStorage.getItem("companyName"),
  };

  if(flowType) {
    body.flowType = flowType;
  }

  if(error) body.error = error

  return dwLogging(
    endpoint, 
    body, 
    headers || headerFromStore
  );
}


export const setCompanyNameWithHeader = (companyName: any, existingHeaders: any ) => {
  let siteIdTemp = ''
  if(companyName?.toUpperCase() === "QUANTUM FIBER") {
    siteIdTemp = 'qf'
  } else if (companyName?.toUpperCase() === "BRIGHTSPEED") {
    siteIdTemp = 'bs'
  } else if (companyName?.toUpperCase() === "CENTURY LINK") {
    siteIdTemp = 'ctl'
  } else {
    siteIdTemp = companyName;
  }
  store?.dispatch(
    setHeaders({
      ...existingHeaders,
      siteId: siteIdTemp,
    })
  );
}

export default DwLogutil
