import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchServiceDetailsLatestAPI } from "../../../../shared/hooks/useFetchServiceDetails";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type ctlPricingDetailsInitialStateType = {
  ctlPricingDetails: any;
  loading: boolean;
  error: any;
};
const initialState: ctlPricingDetailsInitialStateType = {
  ctlPricingDetails: null,
  loading: false,
  error: null,
};

export const getCtlPricingDetails = createAsyncThunk(
  "service/details",
  async (email: string) => {
    try {
      const reduxStore = store.getState();
      const globalToken = reduxStore?.brightspeedToken?.data?.access_token;
      const headers = {
        Authorization:
          process.env.REACT_APP_ENV === "production"
            ? `Bearer ${globalToken}`
            : "Basic"+ btoa('SAP_BOSSOM:8mhpvFXQcbVH>oVYGErDPdZvRYqpYTSeQuURjlWA'),
        brspdChId: process.env.REACT_APP_CHANNEL_ID || "",
      };
      const response = await fetchServiceDetailsLatestAPI(
        {
          "": `${API_URLS?.ctlPricingUrl}`,
        },
        headers
      );
      return response?.data.d?.results;
    } catch (error) {
      throw error;
    }
  }
);

const ctlPricingDetailSlice = createSlice({
  name: "service/details",
  initialState,
  reducers: {
    clearCtlPricingDetailSlice: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCtlPricingDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCtlPricingDetails.fulfilled, (state, action) => {
      state.ctlPricingDetails = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getCtlPricingDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "service details",
        action.error?.message,
        action?.type
      );
      state.ctlPricingDetails = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { clearCtlPricingDetailSlice } = ctlPricingDetailSlice.actions;
export default ctlPricingDetailSlice.reducer;