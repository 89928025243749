import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getRepairAppointment } from "../../../../shared/hooks/useFetchUpcomingAppointment";
import API_URLS from "../../../../shared/utils/ApiUrls";
import {
  checkIsPastDate,
  checkIsUpcommingAppointment,
} from "../../../../shared/utils/dateUtils";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";
import { format } from "date-fns";

type pastRepairAppointmentInitialStateType = {
  pastRepairAppointment: any;
  loading: boolean;
  error: any;
};
const initialState: pastRepairAppointmentInitialStateType = {
  pastRepairAppointment: null,
  loading: false,
  error: null,
};

export const getPastRepairAppointmentDetails = createAsyncThunk(
  "pastRepairAppointment/details",
  async (billingAccountId: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const wirecenter = reduxStore?.addressQualification?.data?.addressQualification?.entity?.wireCenter;
      const response = await getRepairAppointment(
        {
          "": API_URLS?.repairAppointmentURL,
          // "": `${API_URLS?.repairAppointmentURL}${"200005647"}`,
        },
        {
          baseline: {
            transactionId:
              reduxStore?.addressQualification?.data?.addressQualification
                ?.entity?.partnerSessionId || "",
            channel: "web-acm",
            wirecenter: wirecenter || "",
            siteId: headers?.siteId || "bs",
          },
          ban: billingAccountId,
        },
        headers
      );

      if (response?.data?.result?.tickets?.recently_closed?.length > 0) {
        const data = response?.data?.result?.tickets?.recently_closed
          ?.filter((item: any) => {
            if (
              item?.appointment_start &&
              item?.appointment_end &&
              item?.dispatch_status
            ) {
              return item;
            }
          })
          ?.sort(
            (a: any, b: any) =>
              new Date(b?.appointment_start)?.valueOf() -
              new Date(a?.appointment_start).valueOf()
          );
        return {
          result: data?.map((item: any) => {
            return {
              u_appointment_end: item?.appointment_end, //"03/12/2024 17:00",
              number: item?.number,
              u_technician_name: item?.technician_name || "",
              u_appointment_start: item?.appointment_start,
              u_reservation_number: item?.reservation_id,
              // u_appointment: "ccdd93c71bf4ce10cedec998624bcb47",
              u_service_instance_id: item?.service_instance_id,
              u_appointment_tarn: item?.dispatch_tarn,
              u_dispatch_status: item?.dispatch_status,
              u_case_number: item?.number,
            };
          }),
        };
      }

      return {
        result: response?.data?.result?.tickets?.recently_closed?.sort(
          (a: any, b: any) =>
            new Date(b?.appointment_start)?.valueOf() -
            new Date(a?.appointment_start).valueOf()
        ),
      };
    } catch (error) {
      throw error;
    }
  }
);

const pastRepairAppointmentDetailSlice = createSlice({
  name: "pastRepairAppointment/details",
  initialState,
  reducers: {
    pastRepairAppointmentDetailSliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPastRepairAppointmentDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getPastRepairAppointmentDetails.fulfilled,
      (state, action) => {
        state.pastRepairAppointment = action.payload;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(
      getPastRepairAppointmentDetails.rejected,
      (state, action) => {
        errorConditionGTM(
          "BRSPD_Fiber_EC_Flow",
          "burriedDrop details",
          action.error?.message,
          action?.type
        );
        state.pastRepairAppointment = null;
        state.loading = false;
        state.error = action.error;
      }
    );
  },
});

export const { pastRepairAppointmentDetailSliceSetInitialState } =
  pastRepairAppointmentDetailSlice.actions;
export default pastRepairAppointmentDetailSlice.reducer;
