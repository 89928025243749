import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import useFetchEligibleQBPAem from "../../../../shared/hooks/useFetchEligibleQBPAem";
import { errorConditionGTM } from "../../Utils/googleTags";
import useFetchAEMContent from "../../../../shared/hooks/useFetchAemContents";
import useFetchAemBrspdContents from "../../../../shared/hooks/useFetchAemBrspdContents";
import ApiUrls from "../../../../shared/utils/ApiUrls";

type notEligibleQBPPaymentContentInitialStateType = {
    notEligibleQBPPaymentContent: any;
    loading: boolean;
    error: any;
};
const initialState: notEligibleQBPPaymentContentInitialStateType = {
    notEligibleQBPPaymentContent: null,
    loading: false,
    error: null,
};

const host = window?.location?.host;
const file = 'not-eligble-for-payment-arrangements.json'

export const getnotEligibleQBPPaymentContent = createAsyncThunk(
    "get/not_eligible_payment_AEM/content",
    async () => {
        try {
            const response = await useFetchAemBrspdContents({
                [ApiUrls.aem_content]: `${file}?appName=${host}`,
            });
            return response?.data;
        } catch (error) {
            throw error;
        }
    }
);

const getnotEligibleQBPPaymentContentDetailSlice = createSlice({
    name: "not_eligible_payment_AEM/Details",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getnotEligibleQBPPaymentContent.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getnotEligibleQBPPaymentContent.fulfilled, (state, action) => {
            state.notEligibleQBPPaymentContent = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getnotEligibleQBPPaymentContent.rejected, (state, action) => {
            errorConditionGTM(
                "BRSPD_Fiber_EC_Flow",
                "non auth AEM contentstack",
                action.error?.message,
                action?.type
            );
            state.notEligibleQBPPaymentContent = null;
            state.loading = false;
            state.error = action.error;
        });
    },
});

export default getnotEligibleQBPPaymentContentDetailSlice.reducer;