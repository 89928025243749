import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchServiceDetailsLatestAPI } from "../../../../shared/hooks/useFetchServiceDetails";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type ctlCustomerDetailsInitialStateType = {
  ctlCustomerDetails: any;
  loading: boolean;
  error: any;
};
const initialState: ctlCustomerDetailsInitialStateType = {
  ctlCustomerDetails: null,
  loading: false,
  error: null,
};

export const getCtlCustomerDetails = createAsyncThunk(
  "service/details",
  async (email: string) => {
    try {
      const reduxStore = store.getState();
      const globalToken = reduxStore?.brightspeedToken?.data?.access_token;
      const headers = {
        Authorization:
          process.env.REACT_APP_ENV === "production"
            ? `Bearer ${globalToken}`
            : "Basic" + btoa('SAP_BOSSOM:8mhpvFXQcbVH>oVYGErDPdZvRYqpYTSeQuURjlWA'),
        brspdChId: process.env.REACT_APP_CHANNEL_ID || "",
      };
      
      const response = await fetchServiceDetailsLatestAPI(
        {
          "": `${API_URLS?.ctlCustomerUrl}`,
        },
        headers
      );
      return response?.data?.d?.results;
    } catch (error) {
      throw error;
    }
  }
);

const ctlCustomerDetailSlice = createSlice({
  name: "service/details",
  initialState,
  reducers: {
    clearCtlCustomerDetailSlice: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCtlCustomerDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCtlCustomerDetails.fulfilled, (state, action) => {
      state.ctlCustomerDetails = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getCtlCustomerDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "service details",
        action.error?.message,
        action?.type
      );
      state.ctlCustomerDetails = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { clearCtlCustomerDetailSlice } = ctlCustomerDetailSlice.actions;
export default ctlCustomerDetailSlice.reducer;