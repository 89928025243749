import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MigrationState {
    isMigrated: boolean;
    queryParam: string;
}

const initialState: MigrationState = {
    isMigrated: false,
    queryParam: "migratedCTL=Y"
};

export const migrationSlice = createSlice({
    name: 'migration',
    initialState,
    reducers: {
        setMigrated: (state, action: PayloadAction<boolean>) => {
            state.isMigrated = action.payload;
        },
    },
});

export const { setMigrated } = migrationSlice.actions;
export default migrationSlice.reducer;